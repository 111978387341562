import { tailwind as baseStyles } from "@theme-ui/presets"

export default {
  ...baseStyles,
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "medium",
      lineHeight: "medium",
      color: 'blue',
    },
    title: {
      fontFamily: "heading",
      fontWeight: "bold",
      lineHeight: "medium",
    },
    body: {
      fontFamily: "body",
      lineHeight: "normal",
      fontSize: 1,
    },
    content: {
      fontFamily: "body",
      lineHeight: "normal",
      fontSize: 2,
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
    },
    bold: {
      fontWeight: "bold",
    },
    medium: {
      fontWeight: "medium",
    }
  },
  layout: {
    container: {
      border: {
        borderWidth: 2,
        borderColor: 'red',
        bg: 'blue'
      }
    }
  },
  colors: {
    ...baseStyles.colors,
    black: "#000000",
    white: "#ffffff",
    gray: "#6d6e71", 
    darkGray: "#585858",
    primary: "#F7941D",
    secondary: "#000000",
    red: "#A22F32",
    yellow: "#ffd400",
    blue: "#0078b9",
    green: "#007538"
  },
  buttons: {
    primary: {  
      color: 'white',
      bg: 'primary',
      borderWidth: '2px',
      borderColor: 'primary',
      borderStyle: 'solid',
      borderRadius: 'full',
      outline: 'none',
      textAlign: 'center',
      textDecoration: 'none',
      fontSize: 1,
      px: 7,
      py: 2,
      transition: 'all 0.3s ease-in-out',
      '&:hover' : {
        bg: 'black',
      },
    },
    secondary: {
      color: 'white',
      bg: 'transparent',
      borderWidth: '2px',
      borderColor: 'primary',
      borderStyle: 'solid',
      borderRadius: 'full',
      outline: 'none',
      textAlign: 'center',
      textDecoration: 'none',
      fontSize: 1,
      px: 7,
      py: 2,
      transition: 'all 0.3s ease-in-out',
      '&:hover' : {
        bg: 'primary',
      },
    },
    whiteOutline: {
      color: 'white',
      bg: 'transparent',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'white',
      borderRadius: 'full',
      outline: 'none',
      textAlign: 'center',
      textDecoration: 'none',
      fontSize: 1,
      px: 7,
      py: 2,
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        color: 'black',
        bg: 'white'
      }
    },
    orangeOutline: {
      color: 'white',
      bg: 'transparent',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'primary',
      borderRadius: 'full',
      outline: 'none',
      textAlign: 'center',
      textDecoration: 'none',
      fontSize: 1,
      px: 7,
      py: 2,
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        color: 'black',
        bg: 'white'
      }
    },
    gradient: {
      color: 'white',
      background: 'linear-gradient(-2.2deg, #EF3E23 7.3%, #FDCB10 84.56%)',
      border: 'none',
      borderRadius: 0,
    }
  },
  fonts: {
    body: "'Muli', sans-serif",
    heading: "'Muli', sans-serif",
  },
  fontSizes: [14, 18, 20, 28, 40, 60],
  fontWeights: {
    normal: 400,
    medium: 600,
    bold: 800,
    black: 900
  },
  lineHeights: {
    normal: 1.5,
    medium: 1.4,
  },
  space: [0, 4, 8, 12, 16, 20, 24, 32, 40, 48, 56, 64, 72, 80, 128, 256, 512],
  sizes: {
    container: '1200px',
  },
  breakpoints: ["768px", "1025px", "1290px"],
  cards: {
    primary : {
      padding: 0,
      boxShadow: '0px 0px 8px rgba(88,88,88,0.5)'
    }
  },
  styles: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh'
    },
    ...baseStyles.styles,
    h1: {
      fontFamily: 'heading',
      fontSize: 5,
      mt: 2,
    },
    h2: {
      fontFamily: 'heading',
      fontWeight: 'medium',
      fontSize: 4,
      my: 2,
    },
    h3: {
      fontFamily: 'heading',
      fontWeight: 'bold',
      fontSize: 3,
    },
    h4: {
      fontSize: 2,
    },
    h5: {
      fontSize: 2,
    },
    h6: {
      fontSize: 2,
    },
    p: {
      fontSize: 1,
    }
  },
}
